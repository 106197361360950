import React from "react";
import theme from "theme";
import { Theme, Text, Box, Link, Image, Section, Icon, Span, List } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssignmentTurnedIn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | EchoStrings Academy
			</title>
			<meta name={"description"} content={"Откройте для себя мир музыки – игра на гитаре и вокал доступны каждому"} />
			<meta property={"og:title"} content={"Home | EchoStrings Academy"} />
			<meta property={"og:description"} content={"Откройте для себя мир музыки – игра на гитаре и вокал доступны каждому"} />
			<meta property={"og:image"} content={"https://klixom.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://klixom.com/img/10250-2275.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://klixom.com/img/10250-2275.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://klixom.com/img/10250-2275.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://klixom.com/img/10250-2275.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://klixom.com/img/10250-2275.png"} />
			<meta name={"msapplication-TileImage"} content={"https://klixom.com/img/10250-2275.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-12">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="80px 50px 80px 50px"
					lg-width="100%"
					sm-padding="80px 25px 40px 25px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-padding="80px 25px 80px 25px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="flex-start"
					>
						<Text color="--primary" margin="0px 0px 25px 0px" font="normal 400 16px/1.5 --fontFamily-sans" letter-spacing="1px">
						Откройте для себя мир музыки
						</Text>
						<Text
							margin="0px 0px 20px 0px"
							font="normal 400 56px/1.2 --fontFamily-serifGaramond"
							sm-font="normal 700 42px/1.2 --fontFamily-sans"
							color="--dark"
							lg-margin="0px 0px 35px 0px"
						>
							EchoStrings Academy
						</Text>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						sm-flex-direction="column"
						align-items="flex-end"
					>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							width="70%"
							lg-width="60%"
							sm-width="100%"
							sm-margin="0px 0px 25px 0px"
							justify-content="flex-end"
						>
							<Text margin="0px 0px 25px 0px" font="--lead" color="--primary" padding="0px 35px 0px 0px">
							EchoStrings Academy – это ваш первый шаг на пути к музыкальному мастерству. Мы предлагаем онлайн-курсы для всех уровней подготовки, от новичков до продвинутых музыкантов. Здесь вы найдете всё, чтобы начать играть на гитаре и улучшить свои вокальные навыки.
							</Text>
							<Link href="/contact" color="--primary">
							Контакты
							</Link>
						</Box>
					</Box>
				</Box>
				<Image
					src="https://klixom.com/img/1.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section
			padding="80px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fafaf9"
			display="flex"
			quarkly-title="Advantages/Features-28"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="80%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
			>
				<Text as="p" font="--base" color="--primary" margin="0px 0px 8px 0px">
					O Нас
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
				>
					Чем мы отличаемся
				</Text>
				<List
					margin="40px 0px 0px 0px"
					padding="0px 0px 0px 0px"
					as="ul"
					sm-margin="24px 0 0 0"
					list-style-type="none"
					font="normal normal 18px/150% sans-serif"
					display="flex"
					flex-direction="column"
					grid-gap="25px"
					lg-margin="32px 0px 0px 0px"
				>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdAssignmentTurnedIn}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--greyD2" font="18px/27px --fontFamily-sans">
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Широкий спектр уроков:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								От основ гитары до сложных вокальных техник.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdAssignmentTurnedIn}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" color="--darkL2" font="18px/27px --fontFamily-sans">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Профессиональные преподаватели:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--darkL2"
							>
								Наши курсы ведут опытные музыканты и педагоги.
							</Span>
						</Text>
					</Box>
					<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
						<Icon
							display="block"
							category="md"
							icon={MdAssignmentTurnedIn}
							size="20px"
							color="--primary"
							margin="5px 0 0 0"
						/>
						<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--darkL2">
							<Span
								font-weight="600"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="normal 600 16px/1.5 --fontFamily-sans"
								color="--darkL2"
							>
								Гибкость и доступность:{" "}
							</Span>
							<Span
								opacity="0.9"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
								font="--base"
								color="--greyD2"
							>
								Учебный процесс организован так, чтобы вы могли обучаться в любое удобное время, не выходя из дома.
							</Span>
						</Text>
					</Box>
				</List>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Image src="https://klixom.com/img/2.jpg" display="block" border-radius="32px" />
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});